import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';

import ClusterOtelMixin from '../common/cluster-otel.mixin';
import { LOGTYPE } from '../../constants';

/**
 * Mixin for market cluster pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class MarketsClusterOtel extends Mixins(ClusterOtelMixin) {
    @Inject(ClusterMarketsServiceS)
    protected clusterMarketsService!: ClusterMarketsService;

    get payload() {
        const filterToBuild = ['provider', 'month', 'year'];
        const settingsUnion = {
            provider: this.clusterMarketsService.storeState.provider,
            ...this.documentFiltersService.settings,
        } as Record<string, any>;

        const filterBy = filterToBuild.reduce((acc, key) => {
            acc[key] = settingsUnion[key];
            return acc;
        }, {} as any);

        return {
            'cx.action.filterBy': this.otelService.buildFilterBy(filterBy),
        };
    }
}
