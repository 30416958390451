
import { Inject } from 'inversify-props';
import { Component, Prop } from 'vue-property-decorator';

import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import type Day from '@/modules/common/types/day.type';

import CalendarChain from '@/modules/common/components/ui-kit/calendar-chain.vue';

@Component({
    extends: CalendarChain,
})
export default class MarketsCalendarLine extends CalendarChain {
    @Inject(ClusterMarketsServiceS)
    private clusterMarketsService!: ClusterMarketsService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    @Prop({ type: Object })
    private hotelData!: ClusterHotelsMarketsModel;

    @Prop({ type: Boolean })
    public isVisible!: boolean;

    get mainCompset() {
        return this.clusterService.getMainCompset(this.hotelData);
    }

    get calculatedAssestments() {
        if (!this.mainCompset) {
            return this.documentFiltersService.days.map(() => ({
                type: ASSESSMENT_TYPES.NO_DATA,
                isPastDay: false,
                colorModificator: 'white',
                isNA: false,
            }));
        }

        return this.documentFiltersService.days.map((day, index) => {
            const type = this.clusterMarketsService
                .getAssessments(day, this.hotelData.hotelId)!;

            return {
                type,
                isPastDay: this.documentFiltersService.isPreviousDay((index + 1) as Day),
                colorModificator: this.getDayColor(type, (index + 1) as Day),
                isNA: this.notAvailableItems[index],
            };
        });
    }

    get notAvailableItems() {
        return this.documentFiltersService.days
            .map((day: Day) => this.isNa(day));
    }

    private getDayColor(assessmentType: ASSESSMENT_TYPES, day: Day): string {
        const modificators: { [k: number]: string } = {
            [ASSESSMENT_TYPES.GOOD]: 'dark-green',
            [ASSESSMENT_TYPES.NORMAL]: 'green',
            [ASSESSMENT_TYPES.FAIR]: 'yellow',
            [ASSESSMENT_TYPES.BAD]: 'red',
            [ASSESSMENT_TYPES.NO_DATA]: 'white',
            [!this.isNa(day) ? ASSESSMENT_TYPES.SOLD_OUT : -1]: 'grey',
            [this.isNa(day) ? ASSESSMENT_TYPES.SOLD_OUT : -1]: 'red-outline',
        };

        return modificators[assessmentType as number];
    }

    private isNa(day: Day) {
        return this.clusterMarketsService
            .isNA(day, this.hotelData.hotelId);
    }
}
